import LicenceHttpService from "../http/backend.http.service";

class UsagesEndpoint {

	#endpoint = "usage";

	findAll = async (token) => {
		return await LicenceHttpService.get(token, this.#endpoint);
	};

	findPaginated = async (token, page, perPage) => {
		return await LicenceHttpService.get(token, this.#endpoint + "/paginated?size=" + perPage + "&page=" + page);
	};

	deleteUsage = async (token, usageId) => {
		return await LicenceHttpService.delete(token, this.#endpoint + "/" + usageId);
	};

	addUsage = async (token, usageData) => {
		return await LicenceHttpService.post(token, this.#endpoint, usageData);
	};

	updateUsage = async (token, usageId, usageData) => {
		return await LicenceHttpService.put(token, this.#endpoint + "/" + usageId, usageData);
	};
}

export default new UsagesEndpoint();