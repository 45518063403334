import CourseHttpService from "../http/backend.http.service";

class CoursesEndpoint {
	#endpoint = "courses";

	findAll = async (token) => {
		return await CourseHttpService.get(token, this.#endpoint);
	};

	findStudentAllowedCourses = async (token) => {
		return await CourseHttpService.get(token, this.#endpoint + "/student-page");
	};

}

export default new CoursesEndpoint();