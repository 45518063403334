import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import AppCardHeader from "../../components/AppCardHeader";
import {config} from "../../Constants";
import AppDataTable from "../../components/AppDataTable";

function Courses({element}) {
  const navigate = useNavigate();

  const {t} = useTranslation([], {useSuspense: false});

  const handleViewLessonPage = (row) => {
    const path = row.name;
    const rootPath = element.name;
    navigate(`${config.routes.courses.route}/${element.id}/lessons/${row.id}`, {
      state: {
        path,
        rootPath,
      },
    });
  };

  const handleViewLessonScenario = (row) => {
    const path = row.name;
    const rootPath = element.name;
    navigate(
      `${config.routes.courses.route}/${element.id}/lessons/scenario/${row.id}`,
      {
        state: {
          path,
          rootPath,
        },
      },
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <AppCardHeader title={element.name}/>
              <MDBox pt={3}>
                <AppDataTable
                  viewLessonPage={handleViewLessonPage}
                  viewLessonScenario={handleViewLessonScenario}
                  hasActions
                  searchable
                  columns={[
                    {
                      name: t("Course Name"),
                      selector: (row) => row.name,
                    },
                    {
                      name: t("Description"),
                      selector: (row) => row.description,
                    },
                  ]}
                  data={element.lessons}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Courses;
