import CourseHttpService from "../http/backend.http.service";

class LessonsEndpoint {
	#endpoint = "lessons";

	findAll = async (token) => {
		return await CourseHttpService.get(token, this.#endpoint);
	};

	findById = async (token, lessonId) => {
		return await CourseHttpService.get(token, this.#endpoint + "/" + lessonId);
	};

	addLesson = async (token, lessonData) => {
		return await CourseHttpService.post(token, this.#endpoint, lessonData);
	};

	deleteLesson = async (token, lessonId) => {
		return await CourseHttpService.delete(token, this.#endpoint + "/" + lessonId);
	};

	updateLesson = async (token, lessonId, lessonData) => {
		return await CourseHttpService.put(token, this.#endpoint + "/" + lessonId, lessonData);
	};
}

export default new LessonsEndpoint();