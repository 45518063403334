import {format} from "date-fns";
import {enUS, tr} from "date-fns/locale";
import i18n from "../../i18n";

const locales = {
  en: enUS,
  tr: tr
};

export function formatHumanReadableDate(dateString) {
  if (!dateString) {
    return dateString;
  }

  const date = new Date(dateString);
  const locale = locales[i18n.language];
  const formatString = i18n.t("dateFormat");

  return format(date, formatString, {locale});
}

export function formatHumanReadableDatetime(datetime) {
  if (!datetime) {
    return datetime;
  }

  const date = new Date(datetime);
  const locale = locales[i18n.language];
  const formatString = i18n.t("dateTimeFormat");

  return format(date, formatString, {locale});
}


