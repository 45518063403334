import Axios from "axios";
import {config} from "../../Constants";

const API_URL = config.url.BACKEND_API_BASE_URL;
Axios.defaults.baseURL = API_URL;

export class BackendHttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (token, url) => await this.request(this.getOptionsConfig(token, "get", url));

  post = async (token, url, data) => await this.request(this.getOptionsConfig(token, "post", url, data));

  put = async (token, url, data) => await this.request(this.getOptionsConfig(token, "put", url, data));

  patch = async (token, url, data) => await this.request(this.getOptionsConfig(token, "patch", url, data));

  delete = async (token, url) => await this.request(this.getOptionsConfig(token, "delete", url));

  getOptionsConfig = (token, method, url, data) => ({
    method,
    url,
    data,
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      "Access-Control-Allow-Credentials": true,
      Authorization: `Bearer ${token}`,
    },
  });

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res.data))
        .catch((ex) => reject(ex.response.data));
    });
  }
}

export default new BackendHttpService();
