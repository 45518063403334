import { useEffect, useRef } from "react";


const TimeOnSiteTracker = () => {
	const TosRef = useRef(null);

	useEffect(() => {
		let Tos;
		document.addEventListener("DOMContentLoaded", function () {
			let js, fjs = document.getElementsByTagName("script")[0];
			js = document.createElement("script");
			js.onload = function () {
				let config = {};
				if (window.TimeOnSiteTracker) {
					Tos = window.TimeOnSiteTracker(config);
					TosRef.current = Tos;
				}
			};
			js.src = ".././public/assets/js/timeonsitetracker.min";
			fjs.parentNode.insertBefore(js, fjs);
		});
	}, []);

	return TosRef.current;
};

export default TimeOnSiteTracker;