import Permission from "./Permission";

class RolePermission {
  rolesPermissions = {
    SUPER_ADMIN: {
      CRUD_ALL_IN_ALL_DOMAIN: Permission.CRUD_ALL_IN_ALL_DOMAIN,
      PERMISSIONS: Permission.PERMISSIONS,

      ENVIRONMENT_GET: Permission.ENVIRONMENT_GET,
      ENVIRONMENT_POST: Permission.ENVIRONMENT_POST,
      ENVIRONMENT_PUT: Permission.ENVIRONMENT_PUT,
      ENVIRONMENT_DELETE: Permission.ENVIRONMENT_DELETE,
      ENVIRONMENT_DELETE_ALL: Permission.ENVIRONMENT_DELETE_ALL,

      FEEDBACK_GET: Permission.FEEDBACK_GET,
      FEEDBACK_POST: Permission.FEEDBACK_POST,
      FEEDBACK_PUT: Permission.FEEDBACK_PUT,
      FEEDBACK_DELETE: Permission.FEEDBACK_DELETE,

      FLAVOR_GET: Permission.FLAVOR_GET,

      IMAGE_GET: Permission.IMAGE_GET,
      IMAGE_POST: Permission.IMAGE_POST,
      IMAGE_PUT: Permission.IMAGE_PUT,
      IMAGE_DELETE: Permission.IMAGE_DELETE,

      IMAGE_ACCESS_GET: Permission.IMAGE_ACCESS_GET,
      IMAGE_ACCESS_POST: Permission.IMAGE_ACCESS_POST,
      IMAGE_ACCESS_PUT: Permission.IMAGE_ACCESS_PUT,
      IMAGE_ACCESS_DELETE: Permission.IMAGE_ACCESS_DELETE,

      MAIL_SERVER_GET: Permission.MAIL_SERVER_GET,
      MAIL_SERVER_PUT: Permission.MAIL_SERVER_PUT,

      TEMPLATE_GET: Permission.TEMPLATE_GET,
      TEMPLATE_POST: Permission.TEMPLATE_POST,
      TEMPLATE_PUT: Permission.TEMPLATE_PUT,
      TEMPLATE_DELETE: Permission.TEMPLATE_DELETE,

      LICENCE_POST: Permission.LICENCE_POST,
      LICENCE_PUT: Permission.LICENCE_PUT,
      LICENCE_DELETE: Permission.LICENCE_DELETE,

      USAGE_GET: Permission.USAGE_GET,
      USAGE_POST: Permission.USAGE_POST,
      USAGE_DELETE: Permission.USAGE_DELETE,

      USER_GET: Permission.USER_GET,
      USER_POST: Permission.USER_POST,
      USER_PUT: Permission.USER_PUT,
      USER_DELETE: Permission.USER_DELETE,

      GROUP_GET: Permission.GROUP_GET,
      GROUP_POST: Permission.GROUP_POST,
      GROUP_PUT: Permission.GROUP_PUT,
      GROUP_DELETE: Permission.GROUP_DELETE,

      ROLE_GET: Permission.ROLE_GET,
      ROLE_PUT: Permission.ROLE_PUT,

      COURSE_GET: Permission.COURSE_GET,
      COURSE_POST: Permission.COURSE_POST,
      COURSE_PUT: Permission.COURSE_PUT,
      COURSE_DELETE: Permission.COURSE_DELETE,

      SCENARIO_GET: Permission.SCENARIO_GET,
      SCENARIO_POST: Permission.SCENARIO_POST,
      SCENARIO_PUT: Permission.SCENARIO_PUT,
      SCENARIO_DELETE: Permission.SCENARIO_DELETE,

      SCENARIO_STEP_GET: Permission.SCENARIO_STEP_GET,
      SCENARIO_STEP_POST: Permission.SCENARIO_STEP_POST,
      SCENARIO_STEP_PUT: Permission.SCENARIO_STEP_PUT,
      SCENARIO_STEP_DELETE: Permission.SCENARIO_STEP_DELETE,

      VULNERABILITY_GET: Permission.VULNERABILITY_GET,
      VULNERABILITY_POST: Permission.VULNERABILITY_POST,
      VULNERABILITY_PUT: Permission.VULNERABILITY_PUT,
      VULNERABILITY_DELETE: Permission.VULNERABILITY_DELETE,

      FLAG_GET: Permission.FLAG_GET,
      FLAG_POST: Permission.FLAG_POST,
      FLAG_PUT: Permission.FLAG_PUT,
      FLAG_DELETE: Permission.FLAG_DELETE,

      FLAG_ANSWER_GET: Permission.FLAG_ANSWER_GET,
      FLAG_ANSWER_POST: Permission.FLAG_ANSWER_POST,
      FLAG_ANSWER_PUT: Permission.FLAG_ANSWER_PUT,
      FLAG_ANSWER_DELETE: Permission.FLAG_ANSWER_DELETE,

      EXERCISE_GET: Permission.EXERCISE_GET,
      EXERCISE_POST: Permission.EXERCISE_POST,
      EXERCISE_PUT: Permission.EXERCISE_PUT,
      EXERCISE_DELETE: Permission.EXERCISE_DELETE,

      EXERCISE_ANSWER_GET: Permission.EXERCISE_ANSWER_GET,
      EXERCISE_ANSWER_POST: Permission.EXERCISE_ANSWER_POST,
      EXERCISE_ANSWER_PUT: Permission.EXERCISE_ANSWER_PUT,
      EXERCISE_ANSWER_DELETE: Permission.EXERCISE_ANSWER_DELETE,
    },
    ADMIN: {
      CRUD_ALL_IN_OWN_DOMAIN: Permission.CRUD_ALL_IN_OWN_DOMAIN,
      PERMISSIONS: Permission.PERMISSIONS,

      ENVIRONMENT_GET: Permission.ENVIRONMENT_GET,
      ENVIRONMENT_POST: Permission.ENVIRONMENT_POST,
      ENVIRONMENT_PUT: Permission.ENVIRONMENT_PUT,
      ENVIRONMENT_DELETE: Permission.ENVIRONMENT_DELETE,

      FEEDBACK_POST: Permission.FEEDBACK_POST,

      FLAVOR_GET: Permission.FLAVOR_GET,

      IMAGE_GET: Permission.IMAGE_GET,
      IMAGE_POST: Permission.IMAGE_POST,
      IMAGE_PUT: Permission.IMAGE_PUT,
      IMAGE_DELETE: Permission.IMAGE_DELETE,

      IMAGE_ACCESS_GET: Permission.IMAGE_ACCESS_GET,
      IMAGE_ACCESS_POST: Permission.IMAGE_ACCESS_POST,
      IMAGE_ACCESS_PUT: Permission.IMAGE_ACCESS_PUT,
      IMAGE_ACCESS_DELETE: Permission.IMAGE_ACCESS_DELETE,

      TEMPLATE_GET: Permission.TEMPLATE_GET,
      TEMPLATE_POST: Permission.TEMPLATE_POST,
      TEMPLATE_PUT: Permission.TEMPLATE_PUT,
      TEMPLATE_DELETE: Permission.TEMPLATE_DELETE,

      USER_GET: Permission.USER_GET,
      USER_POST: Permission.USER_POST,
      USER_PUT: Permission.USER_PUT,
      USER_DELETE: Permission.USER_DELETE,

      USAGE_GET: Permission.USAGE_GET,
      USAGE_POST: Permission.USAGE_POST,

      GROUP_GET: Permission.GROUP_GET,
      GROUP_POST: Permission.GROUP_POST,
      GROUP_PUT: Permission.GROUP_PUT,
      GROUP_DELETE: Permission.GROUP_DELETE,

      COURSE_GET: Permission.COURSE_GET,
      COURSE_POST: Permission.COURSE_POST,
      COURSE_PUT: Permission.COURSE_PUT,
      COURSE_DELETE: Permission.COURSE_DELETE,

      ROLE_GET: Permission.ROLE_GET,
      ROLE_PUT: Permission.ROLE_PUT,

      SCENARIO_GET: Permission.SCENARIO_GET,
      SCENARIO_POST: Permission.SCENARIO_POST,
      SCENARIO_PUT: Permission.SCENARIO_PUT,
      SCENARIO_DELETE: Permission.SCENARIO_DELETE,

      VULNERABILITY_GET: Permission.VULNERABILITY_GET,

      FLAG_GET: Permission.FLAG_GET,
      FLAG_POST: Permission.FLAG_POST,
      FLAG_PUT: Permission.FLAG_PUT,
      FLAG_DELETE: Permission.FLAG_DELETE,

      FLAG_ANSWER_GET: Permission.FLAG_ANSWER_GET,
      FLAG_ANSWER_POST: Permission.FLAG_ANSWER_POST,
      FLAG_ANSWER_PUT: Permission.FLAG_ANSWER_PUT,
      FLAG_ANSWER_DELETE: Permission.FLAG_ANSWER_DELETE,

      EXERCISE_GET: Permission.EXERCISE_GET,
      EXERCISE_POST: Permission.EXERCISE_POST,
      EXERCISE_PUT: Permission.EXERCISE_PUT,
      EXERCISE_DELETE: Permission.EXERCISE_DELETE,

      EXERCISE_ANSWER_GET: Permission.EXERCISE_ANSWER_GET,
      EXERCISE_ANSWER_POST: Permission.EXERCISE_ANSWER_POST,
      EXERCISE_ANSWER_PUT: Permission.EXERCISE_ANSWER_PUT,
      EXERCISE_ANSWER_DELETE: Permission.EXERCISE_ANSWER_DELETE,
    },
    DEMO: {
      CRUD_ALL_IN_OWN_DOMAIN: Permission.CRUD_ALL_IN_OWN_DOMAIN,
      PERMISSIONS: Permission.PERMISSIONS,

      ENVIRONMENT_GET: Permission.ENVIRONMENT_GET,
      ENVIRONMENT_POST: Permission.ENVIRONMENT_POST,
      ENVIRONMENT_PUT: Permission.ENVIRONMENT_PUT,
      ENVIRONMENT_DELETE: Permission.ENVIRONMENT_DELETE,

      FLAVOR_GET: Permission.FLAVOR_GET,

      IMAGE_GET: Permission.IMAGE_GET,
      IMAGE_POST: Permission.IMAGE_POST,

      IMAGE_ACCESS_GET: Permission.IMAGE_ACCESS_GET,
      IMAGE_ACCESS_POST: Permission.IMAGE_ACCESS_POST,
      IMAGE_ACCESS_PUT: Permission.IMAGE_ACCESS_PUT,
      IMAGE_ACCESS_DELETE: Permission.IMAGE_ACCESS_DELETE,

      FEEDBACK_POST: Permission.FEEDBACK_POST,

      TEMPLATE_GET: Permission.TEMPLATE_GET,
      TEMPLATE_POST: Permission.TEMPLATE_POST,
      TEMPLATE_PUT: Permission.TEMPLATE_PUT,
      TEMPLATE_DELETE: Permission.TEMPLATE_DELETE,

      USER_GET: Permission.USER_GET,

      USAGE_GET: Permission.USAGE_GET,
      USAGE_POST: Permission.USAGE_POST,

      GROUP_GET: Permission.GROUP_GET,
      GROUP_POST: Permission.GROUP_POST,

      COURSE_GET: Permission.COURSE_GET,
      COURSE_POST: Permission.COURSE_POST,

      ROLE_GET: Permission.ROLE_GET,

      SCENARIO_GET: Permission.SCENARIO_GET,
      SCENARIO_POST: Permission.SCENARIO_POST,

      SCENARIO_STEP_GET: Permission.SCENARIO_STEP_GET,
      SCENARIO_STEP_POST: Permission.SCENARIO_STEP_POST,

      VULNERABILITY_GET: Permission.VULNERABILITY_GET,

      FLAG_GET: Permission.FLAG_GET,
      FLAG_POST: Permission.FLAG_POST,

      FLAG_ANSWER_GET: Permission.FLAG_ANSWER_GET,
      FLAG_ANSWER_POST: Permission.FLAG_ANSWER_POST,

      EXERCISE_GET: Permission.EXERCISE_GET,
      EXERCISE_POST: Permission.EXERCISE_POST,

      EXERCISE_ANSWER_GET: Permission.EXERCISE_ANSWER_GET,
      EXERCISE_ANSWER_POST: Permission.EXERCISE_ANSWER_POST,
    },
    USER: {
      ENVIRONMENT_POST: Permission.ENVIRONMENT_POST,

      FEEDBACK_POST: Permission.FEEDBACK_POST,

      IMAGE_ACCESS_GET: Permission.IMAGE_ACCESS_GET,

      USAGE_POST: Permission.USAGE_POST,

      GROUP_GET: Permission.GROUP_GET,

      SCENARIO_GET: Permission.SCENARIO_GET,

      VULNERABILITY_GET: Permission.VULNERABILITY_GET,

      FLAG_GET: Permission.FLAG_GET,
      FLAG_POST: Permission.FLAG_POST,
      FLAG_PUT: Permission.FLAG_PUT,
      FLAG_DELETE: Permission.FLAG_DELETE,

      EXERCISE_ANSWER_GET: Permission.EXERCISE_ANSWER_GET,
      EXERCISE_ANSWER_POST: Permission.EXERCISE_ANSWER_POST,
      EXERCISE_ANSWER_PUT: Permission.EXERCISE_ANSWER_PUT,
      EXERCISE_ANSWER_DELETE: Permission.EXERCISE_ANSWER_DELETE,
    }
  };

  getPermissions(roles) {
    const permissions = [];
    const extendedRoles = ["offline_access", "uma_authorization"];
    roles.forEach(role => {
      if (extendedRoles.includes(role)) {
        return;
      }

      const rolePermissions = this.rolesPermissions[role];
      Object.values(rolePermissions)
        .forEach(permission => {
          if (!permissions.includes(permission)) {
            permissions.push(permission);
          }
        });
    });
    return permissions;
  }
}

export default new RolePermission();