import OrchestrationHttpService from "../http/backend.http.service";

class EnvironmentsEndpoint {
	#endpoint = "environments";

	findAll = async (token) => {
		return await OrchestrationHttpService.get(token, this.#endpoint);
	};

	save = async (token, environment) => {
		return await OrchestrationHttpService.post(token, this.#endpoint, environment);
	};

	getByTemplateIdForOwner = async (token, templateId) => {
		return await OrchestrationHttpService.get(token, this.#endpoint + "/by-template/" + templateId + "/LESSON");
	};

	delete(token, id) {
		return OrchestrationHttpService.delete(token, `${this.#endpoint}/${id}`);
	}
}

export default new EnvironmentsEndpoint();