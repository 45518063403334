import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Grid, Paper, Step, StepLabel, Stepper, Typography,} from "@mui/material";
import LessonsEndpoint from "../../services/course/lessons.endpoint";
import ScenariosEndpoint from "../../services/scenarios/scenarios.endpoint";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import AppCardHeader from "../../components/AppCardHeader";
import DOMPurify from "dompurify";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import {config} from "../../Constants";

function Scenarios() {
  const {lessonId} = useParams();
  const navigate = useNavigate();
  const [lessonInfo, setLessonInfo] = useState({});
  const [scenario, setScenario] = useState();
  const {keycloak} = useKeycloak();
  const {t} = useTranslation([], {useSuspense: false});
  const [activeStep, setActiveStep] = useState(0);
  const [expanded, setExpanded] = useState("panel1");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setExpanded("panel1");
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setExpanded("panel1");
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    LessonsEndpoint.findById(keycloak.token, lessonId)
      .then((response) => {
        setLessonInfo(response);
        if (response.scenarioId) {
          ScenariosEndpoint.findByIdWithoutPermissions(
            keycloak.token,
            response.scenarioId,
          )
            .then((scenarioResponse) => setScenario(scenarioResponse));
        }
      });
  }, [keycloak.token, lessonId]);

  const customSanitize = (dirty) => {
    return DOMPurify.sanitize(dirty, {
      ALLOWED_TAGS: [
        "b",
        "i",
        "em",
        "strong",
        "a",
        "p",
        "img",
        "div",
        "span",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "br",
        "blockquote",
        "li",
        "ul",
        "em",
        "u",
      ],
      ALLOWED_ATTR: ["href", "src", "alt", "title", "style", "class"],
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Grid
        sx={{
          pt: 6,
          pb: 3,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <AppCardHeader title={lessonInfo.name}/>
              <Box pt={3} sx={{padding: "1em"}}>
                {scenario ? (
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Stepper activeStep={activeStep}>
                      {scenario.scenarioSteps.map((step) => (
                        <Step key={step.name}>
                          <StepLabel>{step.name}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        mt: 3,
                      }}
                    >
                      <Paper
                        elevation={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 60,
                          px: 3,
                          bgcolor: "#afafaf",
                          borderRadius: 2,
                          boxShadow: 1,
                        }}
                      >
                        <Typography variant="h3" sx={{color: "#333"}}>
                          {scenario.scenarioSteps[activeStep]?.name}
                        </Typography>
                      </Paper>

                      {activeStep === scenario.scenarioSteps.length ? (
                        <Box
                          sx={{
                            mt: 2,
                            mb: 1,
                          }}
                        >
                          <Typography>
                            {t("All steps completed - you're finished")}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Box sx={{flex: "1 1 auto"}}/>
                            <Button onClick={handleReset}>Reset</Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: 2,
                          }}
                        >
                          <MDButton
                            color="info"
                            variant="outlined"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                          >
                            {t("Back")}
                          </MDButton>
                          <Box sx={{flex: "1 1 auto"}}/>
                          <MDButton
                            color="warning"
                            variant="gradient"
                            startIcon={<Icon>edit</Icon>}
                            onClick={() =>
                              navigate(
                                `${config.routes.courses.route}/${lessonInfo.courseId}/lessons/${lessonId}`,
                                {
                                  state: {
                                    rootPath: lessonInfo.courseId,
                                    path: lessonInfo.name,
                                  },
                                },
                              )
                            }
                            sx={{mr: 1}}
                          >
                            {t("Lesson")}
                          </MDButton>
                          <Box sx={{flex: "1 1 auto"}}/>
                          <MDButton
                            color="info"
                            variant="outlined"
                            onClick={handleNext}
                            disabled={
                              activeStep === scenario.scenarioSteps.length - 1
                            }
                          >
                            {t("Next")}
                          </MDButton>
                        </Box>
                      )}
                      <Box
                        sx={{
                          width: "100%",
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                        >
                          <AccordionSummary
                            expandIcon={
                              <Icon fontSize="small">expand_more</Icon>
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{
                              backgroundColor: "#afafaf",
                              borderBottom: "1px solid #ddd",
                              borderRadius: 5,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography align="center" sx={{width: "100%"}}>
                              {t("Expectation")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Typography align="center">
                              {scenario.scenarioSteps[activeStep]?.expectation}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handleChange("panel2")}
                        >
                          <AccordionSummary
                            expandIcon={
                              <Icon fontSize="small">expand_more</Icon>
                            }
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            sx={{
                              backgroundColor: "#afafaf",
                              borderBottom: "1px solid #ddd",
                              borderRadius: 5,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography align="center" sx={{width: "100%"}}>
                              {t("Action")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: customSanitize(
                                  scenario.scenarioSteps[activeStep]?.action,
                                ),
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel3"}
                          onChange={handleChange("panel3")}
                        >
                          <AccordionSummary
                            expandIcon={
                              <Icon fontSize="small">expand_more</Icon>
                            }
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            sx={{
                              backgroundColor: "#afafaf",
                              borderBottom: "1px solid #ddd",
                              borderRadius: 5,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography align="center" sx={{width: "100%"}}>
                              {t("Result")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Typography>
                              {scenario.scenarioSteps[activeStep]?.result}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel4"}
                          onChange={handleChange("panel4")}
                        >
                          <AccordionSummary
                            expandIcon={
                              <Icon fontSize="small">expand_more</Icon>
                            }
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                            sx={{
                              backgroundColor: "#afafaf",
                              borderBottom: "1px solid #ddd",
                              borderRadius: 5,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography align="center" sx={{width: "100%"}}>
                              {t("Step Type")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Typography>
                              {scenario.scenarioSteps[activeStep]?.stepType}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        {scenario.scenarioSteps[activeStep]?.vulnerability && (
                          <Accordion
                            expanded={expanded === "panel5"}
                            onChange={handleChange("panel5")}
                          >
                            <AccordionSummary
                              expandIcon={
                                <Icon fontSize="small">expand_more</Icon>
                              }
                              aria-controls="panel5bh-content"
                              id="panel5bh-header"
                              sx={{
                                backgroundColor: "#afafaf",
                                borderBottom: "1px solid #ddd",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography align="center" sx={{width: "100%"}}>
                                {t("Vulnerability")}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                display: "flex",
                                justifyContent: "center"
                              }}
                            >
                              <Typography>
                                {
                                  scenario.scenarioSteps[activeStep]
                                    ?.vulnerability?.name
                                }
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{fontSize: "1rem"}}>
                    {t("noAssociatedScenario")}
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Scenarios;
