import ScenarioHttpService from "../http/backend.http.service";

class ScenariosEndpoint {
  #endpoint = "scenarios";

  findAll = async (token) => {
    return await ScenarioHttpService.get(token, this.#endpoint);
  };

  findByIdWithoutPermissions = async (token, id) => {
    return await ScenarioHttpService.get(token, this.#endpoint + "/" + id + "/without-permissions");
  };

  addScenario = async (token, scenarioData) => {
    return await ScenarioHttpService.post(token, this.#endpoint, scenarioData);
  };

  deleteScenario = async (token, scenarioId) => {
    return await ScenarioHttpService.delete(token, this.#endpoint + "/" + scenarioId);
  };

  updateScenario = async (token, scenarioId, scenarioData) => {
    return await ScenarioHttpService.put(token, this.#endpoint + "/" + scenarioId, scenarioData);
  };
}

export default new ScenariosEndpoint();