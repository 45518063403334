const environmentStatusType = {
	CREATING: "CREATING",
	CREATED: "CREATED",
	VPN_CREATING: "VPN_CREATING",
	VPN_CREATED: "VPN_CREATED",
	DELETING: "DELETING",
	DELETED: "DELETED",
	UPDATING: "UPDATING",
	UPDATED: "UPDATED",
	ERROR: "ERROR",
	NONE: "NONE"
};

export default environmentStatusType;