import React from "react";
import PropTypes from "prop-types";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

function AppCardHeader({title, addButton, onAdd, ...rest}) {
	return (
		<MDBox
			mx={2}
			mt={-3}
			py={3}
			px={2}
			variant={rest.variant}
			bgColor={rest.bgColor}
			borderRadius={rest.borderRadius}
			coloredShadow={rest.coloredShadow}
		>
			<MDTypography variant="h6" color="white">
				{title}
			</MDTypography>
			{
				addButton && (
					<Fab
						color="warning"
						aria-label="add"
						size="medium"
						sx={{
							position: "absolute",
							top: -12,
							right: 36,
							zIndex: 1,
						}}
						onClick={onAdd}
					>
						<AddIcon fontSize="large" />
					</Fab>
				)
			}
		</MDBox>
	);
}

AppCardHeader.defaultProps = {
	onAdd: () => {},
	addButton: false,
	variant: "gradient",
	bgColor: "info",
	borderRadius: "lg",
	coloredShadow: "info",
};

AppCardHeader.propTypes = {
	title: PropTypes.string.isRequired,
	addButton: PropTypes.bool,
	onAdd: PropTypes.func,
	variant: PropTypes.string,
	bgColor: PropTypes.string,
	borderRadius: PropTypes.string,
	coloredShadow: PropTypes.string,
};

export default AppCardHeader;