import React, {useCallback, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web";
import {Link, useNavigate, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import typography from "assets/theme/base/typography";
import {Input, Stack, Typography} from "@mui/material";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import AppCardHeader from "../../components/AppCardHeader";
import LessonsEndpoint from "../../services/course/lessons.endpoint";
import MDButton from "../../components/MDButton";
import EnvironmentsEndpoint from "../../services/orchestration/environments.endpoint";
import ExercisesEndpoint from "../../services/course/exercises.endpoint";
import AppDataTable from "../../components/AppDataTable";
import ExerciseAnswersEndpoint from "../../services/course/exercise.answers.endpoint";
import AppDataTableBooleanCell from "../../components/AppDataTableBooleanCell";
import environmentStatusType from "../../utils/static/environmentStatus.type";
import {config} from "../../Constants";
import DataTable from "../../components/Tables/DataTable";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";

function Lessons() {
  const {lessonId} = useParams();
  const [lessonInfo, setLessonInfo] = useState({});
  const [exercises, setExercises] = useState([]);
  const [answerData, setAnswerData] = useState({});
  const {keycloak} = useKeycloak();
  const navigate = useNavigate();
  const {t} = useTranslation([], {useSuspense: false});
  const [refreshExerciseTableData, setRefreshExerciseTableData] =
    useState(false);
  const [environmentStatus, setEnvironmentStatus] = useState(
    environmentStatusType.NONE,
  );
  const [environment, setEnvironment] = useState({});
  const [refreshEnvironmentInfo, setRefreshEnvironmentInfo] = useState(true);
  const {size} = typography;
  let intervalId;

  const updateLessonInfo = useCallback(() => {
    LessonsEndpoint.findById(keycloak.token, lessonId)
      .then((response) => {
        setLessonInfo(response);
        EnvironmentsEndpoint.getByTemplateIdForOwner(
          keycloak.token,
          response.templateId,
        )
          .then((environment) => {
            if (environment === "") {
              setEnvironmentStatus(environmentStatusType.NONE);
            } else {
              const {status} = environment;
              setEnvironment(environment);
              setEnvironmentStatus(environmentStatusType[status]);
            }
          });
      });
  });

  useEffect(() => {
    updateLessonInfo();
  }, [refreshEnvironmentInfo]);

  useEffect(() => {
    intervalId = setInterval(() => {
      updateLessonInfo();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    ExercisesEndpoint.getExerciseTable(keycloak.token, lessonId)
      .then(
        (exerciseData) => {
          setExercises(exerciseData);
        },
      );
  }, [refreshExerciseTableData]);

  const handleStart = () => {
    EnvironmentsEndpoint.save(keycloak.token, {
      templateId: lessonInfo.templateId,
      type: "LESSON",
    })
      .then(() => {
        setRefreshEnvironmentInfo(!refreshEnvironmentInfo);
        toast.success(t("Environment started"));
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleDelete = () => {
    EnvironmentsEndpoint.delete(keycloak.token, environment.id)
      .then(() => {
        setRefreshEnvironmentInfo(!refreshEnvironmentInfo);
        toast.success("Successfully deleted");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handlePostExerciseAnswer = (answerData) => {
    ExerciseAnswersEndpoint.postAnswer(keycloak.token, answerData)
      .then(() => {
        toast.success(t("Answer successfully sent"));
        setRefreshExerciseTableData(!refreshExerciseTableData);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleScenario = () => {
    navigate(
      `${config.routes.courses.route}/${lessonId}/lessons/scenario/${lessonId}`,
      {
        state: {
          rootPath: lessonInfo.name,
          path: lessonInfo.name,
        },
      },
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Card>
              <AppCardHeader title={t("About the Environment")}/>
              <MDBox pt={3} style={{padding: "1em"}}>
                {environmentStatus === environmentStatusType.NONE ||
                environmentStatus === environmentStatusType.DELETED ||
                environmentStatus === environmentStatusType.ERROR ? (
                  <Box display="flex" alignItems="center" mb={2}>
                    <MDButton
                      color="warning"
                      onClick={handleScenario}
                      variant="gradient"
                      startIcon={<Icon>book</Icon>}
                      style={{marginRight: "15px"}}
                    >
                      {t("Scenario")}
                    </MDButton>
                    <MDButton
                      color="info"
                      onClick={handleStart}
                      variant="gradient"
                      startIcon={<Icon>play_arrow</Icon>}
                      style={{marginRight: "15px"}}
                    >
                      {t("Start")}
                    </MDButton>
                  </Box>
                ) : environmentStatus === environmentStatusType.CREATED ||
                environmentStatus === environmentStatusType.VPN_CREATED ||
                environmentStatus === environmentStatusType.UPDATED ? (
                  <Box display="flex" alignItems="center" mb={2}>
                    <MDButton
                      color="warning"
                      onClick={handleScenario}
                      variant="gradient"
                      startIcon={<Icon>book</Icon>}
                      style={{marginRight: "15px"}}
                    >
                      {t("Scenario")}
                    </MDButton>
                    <MDButton
                      color="error"
                      onClick={handleDelete}
                      variant="gradient"
                      startIcon={<Icon>delete</Icon>}
                      style={{marginRight: "15px"}}
                    >
                      {t("delete")}
                    </MDButton>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" mb={2}>
                    <MDButton
                      color="warning"
                      onClick={handleScenario}
                      variant="gradient"
                      startIcon={<Icon>book</Icon>}
                      style={{marginRight: "15px"}}
                    >
                      {t("Scenario")}
                    </MDButton>
                    <MDButton
                      disabled
                      color="secondary"
                      variant="gradient"
                      style={{marginRight: "15px"}}
                    >
                      <CircularProgress
                        size={24}
                        sx={{marginRight: "1em"}}
                        color="secondary"
                      />
                      {environmentStatus === environmentStatusType.CREATING
                        ? t("Creating")
                        : environmentStatus ===
                        environmentStatusType.VPN_CREATING
                          ? t("Creating VPN")
                          : environmentStatus === environmentStatusType.DELETING
                            ? t("Deleting")
                            : t("Updating")}
                    </MDButton>
                  </Box>
                )}
                <Typography variant="body1">
                  {lessonInfo.description}
                </Typography>
                {environmentStatus === environmentStatusType.ERROR ? (
                  <MDBox
                    style={{
                      margin: "1em",
                    }}
                  >
                    <Typography
                      variant="body1">{t("ERROR! Contact your administrator in the case of repeated error when starting an environment.")}</Typography>
                  </MDBox>
                ) : null}
              </MDBox>
            </Card>
          </Grid>
          {(environmentStatus === environmentStatusType.CREATED ||
            environmentStatus === environmentStatusType.VPN_CREATED ||
            environmentStatus === environmentStatusType.UPDATED) && (
            <Grid item xs={6}>
              <Card>
                <AppCardHeader title={t("Environment Access")}/>
                <MDBox pt={3} style={{padding: "1em"}}>
                  <DataTable
                    table={{
                      columns: [
                        {
                          Header: t("Name"),
                          accessor: "name",
                          align: "center",
                        },
                        {
                          Header: "IP",
                          accessor: "ip",
                          align: "center",
                        },
                        {
                          Header: t("Environment Access"),
                          Cell: ({row}) => (
                            <Stack direction="row" spacing={2}>
                              {row.original.environmentAccesses.map(
                                (environmentAccess) => (
                                  <Link
                                    to={
                                      config.url.GUACAMOLE_BASE_URL +
                                      environmentAccess.link
                                    }
                                    target="_blank"
                                  >
                                    <MDButton
                                      variant="outlined"
                                      color="info"
                                      size="small"
                                    >
                                      {environmentAccess.protocol}
                                    </MDButton>
                                  </Link>
                                ),
                              )}
                            </Stack>
                          ),
                          align: "center",
                        },
                      ],
                      rows: environment.environmentInstances,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      {(environmentStatus === environmentStatusType.CREATED ||
        environmentStatus === environmentStatusType.VPN_CREATED ||
        environmentStatus === environmentStatusType.UPDATED) && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <AppCardHeader title={t("Exercises")}/>
                <MDBox pt={3} style={{padding: "1em"}}>
                  {exercises.length !== 0 && (
                    <AppDataTable
                      columns={[
                        {
                          name: t("Question"),
                          selector: (row) => row.question,
                        },
                        {
                          name: t("Answer Input"),
                          cell: (row) => (
                            <Input
                              type="text"
                              sx={{textAlign: "center"}}
                              disabled={row.noOfTries === 0 || row.result}
                              defaultValue={row.result ? row.answer : null}
                              onChange={(e) => {
                                const exerciseAnswerData = {
                                  exerciseId: row.id,
                                  answer: e.target.value,
                                };
                                setAnswerData(exerciseAnswerData);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handlePostExerciseAnswer(answerData);
                                }
                              }}
                            />
                          ),
                        },
                        {
                          name: t("Result"),
                          button: true,
                          cell: (row) => (
                            <AppDataTableBooleanCell value={row.result}/>
                          ),
                        },
                        {
                          name: t("Score"),
                          selector: (row) => row.score,
                          button: true,
                        },
                        {
                          name: t("Number of Tries"),
                          selector: (row) => row.noOfTries,
                          button: true,
                        },
                      ]}
                      data={exercises}
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Lessons;
