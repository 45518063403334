import BackendHttpService from "./backend.http.service";

export const setupAxiosInterceptors = (onUnauthenticated) => {
	const onRequestSuccess = async (config) => {
		return config;
	};

	const onRequestFail = (error) => Promise.reject(error);

	BackendHttpService.addRequestInterceptor(onRequestSuccess, onRequestFail);

	const onResponseSuccess = (response) => response;

	const onResponseFail = (error) => {
		const status = error.status || error.response.status;
		if (status === 401)
			onUnauthenticated();

		return Promise.reject(error);
	};

	BackendHttpService.addResponseInterceptor(onResponseSuccess, onResponseFail);
};
