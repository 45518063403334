import {useEffect, useState} from "react";

// react-router components
import {useLocation, useNavigate} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import {useKeycloak} from "@react-keycloak/web";
import {useTranslation} from "react-i18next";
import MDBox from "../../MDBox";

// Material Dashboard 2 React example components
import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";

// Custom styles for DashboardNavbar
import {navbar, navbarContainer, navbarMobileMenu, navbarRow,} from "./styles";

// Material Dashboard 2 React context
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useMaterialUIController,} from "../../../context";
import MDButton from "../../MDButton";

function DashboardNavbar({
  absolute,
  light,
  isMini
}) {
  const {keycloak} = useKeycloak();
  const navigate = useNavigate();
  const {t} = useTranslation([], {useSuspense: false});
  const handleLogInOut = () => {
    if (keycloak.authenticated) {
      navigate("/");
      keycloak.logout();
    } else {
      keycloak.login();
    }
  };

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation()
    .pathname
    .split("/")
    .slice(1);

  useEffect(() => {
    // Setting the navbar type

    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */

    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.

    handleTransparentNavbar();

    // Remove event listener on cleanup

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{mt: 2}}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages"/>
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions"/>
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed"/>
    </Menu>
  );

  // Styles for the navbar icons

  const iconsStyle = ({
    palette: {
      dark,
      white,
      text
    },
    functions: {rgba}
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, {
        transparentNavbar,
        absolute,
        light,
        darkMode,
      })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{
          xs: 1,
          md: 0
        }} sx={(theme) => navbarRow(theme, {isMini})}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
            <MDBox display="flex" alignItems="center" color={light ? "white" : "inherit"}>
              {renderMenu()}
              <MDBox>
                <IconButton
                  color="inherit"
                  disableRipple
                  onClick={handleMiniSidenav}
                  size="small"
                  sx={navbarMobileMenu}
                >
                  <Icon fontSize="medium" sx={iconsStyle}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                <MDButton
                  variant="gradient"
                  startIcon={<Icon>logout</Icon>}
                  color="info"
                  fullWidth
                  type="button"
                  onClick={handleLogInOut}
                >
                  {t("logout")}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
