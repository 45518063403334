const Permission = {
	CRUD_ALL_IN_ALL_DOMAIN: "CRUD_ALL_IN_ALL_DOMAIN",
	CRUD_ALL_IN_OWN_DOMAIN: "CRUD_ALL_IN_OWN_DOMAIN",
	PERMISSIONS: "PERMISSIONS",

	ENVIRONMENT_GET: "ENVIRONMENT_GET",
	ENVIRONMENT_POST: "ENVIRONMENT_POST",
	ENVIRONMENT_PUT: "ENVIRONMENT_PUT",
	ENVIRONMENT_DELETE: "ENVIRONMENT_DELETE",
	ENVIRONMENT_DELETE_ALL: "ENVIRONMENT_DELETE_ALL",

	FEEDBACK_GET: "FEEDBACK_GET",
	FEEDBACK_POST: "FEEDBACK_POST",
	FEEDBACK_PUT: "FEEDBACK_PUT",
	FEEDBACK_DELETE: "FEEDBACK_DELETE",

	FLAVOR_GET: "FLAVOR_GET",

	IMAGE_GET: "IMAGE_GET",
	IMAGE_POST: "IMAGE_POST",
	IMAGE_PUT: "IMAGE_PUT",
	IMAGE_DELETE: "IMAGE_DELETE",

	IMAGE_ACCESS_GET: "IMAGE_ACCESS_GET",
	IMAGE_ACCESS_POST: "IMAGE_ACCESS_POST",
	IMAGE_ACCESS_PUT: "IMAGE_ACCESS_PUT",
	IMAGE_ACCESS_DELETE: "IMAGE_ACCESS_DELETE",

	MAIL_SERVER_GET: "MAIL_SERVER_GET",
	MAIL_SERVER_PUT: "MAIL_SERVER_PUT",

	REALM_GET: "REALM_GET",
	REALM_POST: "REALM_POST",
	REALM_PUT: "REALM_PUT",
	REALM_DELETE: "REALM_DELETE",

	TEMPLATE_GET: "TEMPLATE_GET",
	TEMPLATE_POST: "TEMPLATE_POST",
	TEMPLATE_PUT: "TEMPLATE_PUT",
	TEMPLATE_DELETE: "TEMPLATE_DELETE",

	LICENCE_POST: "LICENCE_POST",
	LICENCE_PUT: "LICENCE_PUT",
	LICENCE_DELETE: "LICENCE_DELETE",

	USAGE_GET: "USAGE_GET",
	USAGE_POST: "USAGE_POST",
	USAGE_DELETE: "USAGE_DELETE",

	USER_GET: "USER_GET",
	USER_POST: "USER_POST",
	USER_PUT: "USER_PUT",
	USER_DELETE: "USER_DELETE",

	GROUP_GET: "GROUP_GET",
	GROUP_POST: "GROUP_POST",
	GROUP_PUT: "GROUP_PUT",
	GROUP_DELETE: "GROUP_DELETE",

	COURSE_GET: "COURSE_GET",
	COURSE_POST: "COURSE_POST",
	COURSE_PUT: "COURSE_PUT",
	COURSE_DELETE: "COURSE_DELETE",

	ROLE_GET: "ROLE_GET",
	ROLE_PUT: "ROLE_PUT",

	SCENARIO_GET: "SCENARIO_GET",
	SCENARIO_POST: "SCENARIO_POST",
	SCENARIO_PUT: "SCENARIO_PUT",
	SCENARIO_DELETE: "SCENARIO_DELETE",

	SCENARIO_STEP_GET: "SCENARIO_STEP_GET",
	SCENARIO_STEP_POST: "SCENARIO_STEP_POST",
	SCENARIO_STEP_PUT: "SCENARIO_STEP_PUT",
	SCENARIO_STEP_DELETE: "SCENARIO_STEP_DELETE",

	VULNERABILITY_GET: "VULNERABILITY_GET",
	VULNERABILITY_POST: "VULNERABILITY_POST",
	VULNERABILITY_PUT: "VULNERABILITY_PUT",
	VULNERABILITY_DELETE: "VULNERABILITY_DELETE",

	FLAG_GET: "FLAG_GET",
	FLAG_POST: "FLAG_POST",
	FLAG_PUT: "FLAG_PUT",
	FLAG_DELETE: "FLAG_DELETE",

	FLAG_ANSWER_GET: "FLAG_ANSWER_GET",
	FLAG_ANSWER_POST: "FLAG_ANSWER_POST",
	FLAG_ANSWER_PUT: "FLAG_ANSWER_PUT",
	FLAG_ANSWER_DELETE: "FLAG_ANSWER_DELETE",

	EXERCISE_GET: "EXERCISE_GET",
	EXERCISE_POST: "EXERCISE_POST",
	EXERCISE_PUT: "EXERCISE_PUT",
	EXERCISE_DELETE: "EXERCISE_DELETE",

	EXERCISE_ANSWER_GET: "EXERCISE_ANSWER_GET",
	EXERCISE_ANSWER_POST: "EXERCISE_ANSWER_POST",
	EXERCISE_ANSWER_PUT: "EXERCISE_ANSWER_PUT",
	EXERCISE_ANSWER_DELETE: "EXERCISE_ANSWER_DELETE",
};

export default Permission;