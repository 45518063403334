// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

// Material Dashboard 2 React base styles
import typography from "../../assets/theme/base/typography";

function Footer({
  company,
  links
}) {
  const {
    href,
    name
  } = company;
  const {size} = typography;

  const renderLinks = () => {
    return links.map((link) => {
      return (
        <MDBox component="li" key={link.name} lineHeight={1} px={2}>
          <Link href={link.href} target="_blank">
            <MDTypography color="text" fontWeight="regular" variant="button">
              {link.name}
            </MDTypography>
          </Link>
        </MDBox>
      );
    });
  };

  return (
    <MDBox
      alignItems="center"
      display="flex"
      flexDirection={{
        xs: "column",
        lg: "row"
      }}
      justifyContent="space-between"
      px={1.5}
      width="100%"
    >
      <MDBox
        alignItems="center"
        color="text"
        display="flex"
        flexWrap="wrap"
        fontSize={size.sm}
        justifyContent="center"
        px={1.5}
      >
        &copy; 2017-{new Date().getFullYear()},
        <Link href={href} target="_blank">
          <MDTypography fontWeight="medium" variant="button">
            &nbsp;{name}&nbsp;
          </MDTypography>
        </Link>
      </MDBox>
      <MDBox
        component="ul"
        sx={({breakpoints}) => {
          return {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0
            }
          };
        }}
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: {
    href: "https://bilgem.tubitak.gov.tr/sge/",
    name: "TÜBİTAK BİLGEM Siber Güvenlik Enstitüsü"
  },
  links: [
    // {
    //   href: "https://www.tubitak.gov.tr/",
    //   name: "Tübitak"
    // },
    // {
    //   href: "https://bilgem.tubitak.gov.tr/",
    //   name: "Tübitak Bilgem"
    // },
    // {
    //   href: "https://bilgem.tubitak.gov.tr/sge/",
    //   name: "TÜBİTAK BİLGEM Siber Güvenlik Enstitüsü"
    // }
  ]
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object)
};

export default Footer;
