import CourseHttpService from "../http/backend.http.service";

class ExerciseAnswersEndpoint {
	#endpoint = "exercise-answers";

	getScores = async (token, lessonId) => {
		return await CourseHttpService.get(token, this.#endpoint + "/" + lessonId + "/get-scores");
	};

	postAnswer = async (token, exerciseAnswerData) => {
		return await CourseHttpService.post(token, this.#endpoint, exerciseAnswerData);
	};

}

export default new ExerciseAnswersEndpoint();