// react-router-dom components
import {Link, useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import {Breadcrumbs as MuiBreadcrumbs} from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import {isValidUUID} from "../../utils/static/Validator";
import {useTranslation} from "react-i18next";

function Breadcrumbs({
  icon,
  title,
  route,
  light
}) {
  const routes = route.slice(0, -1);
  const location = useLocation();
  const {t} = useTranslation([], {useSuspense: false});

  return (
    <MDBox
      mr={{
        xs: 0,
        xl: 8,
      }}
    >
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({
              palette: {
                white,
                grey
              }
            }) => {
              return light ? white.main : grey[600];
            },
          },
        }}
      >
        <Link to="/">
          <MDTypography
            color={light ? "white" : "dark"}
            component="span"
            opacity={light ? 0.8 : 0.5}
            sx={{lineHeight: 0}}
            variant="body2"
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {routes.map((el) => {
          return (
            <Link key={el} to={`/${el}`}>
              <MDTypography
                color={light ? "white" : "dark"}
                component="span"
                fontWeight="regular"
                opacity={light ? 0.8 : 0.5}
                sx={{lineHeight: 0}}
                textTransform="capitalize"
                variant="button"
              >
                {isValidUUID(el) ? t(location.state?.rootPath) : t(el)}
              </MDTypography>
            </Link>
          );
        })}
        <MDTypography
          color={light ? "white" : "dark"}
          fontWeight="regular"
          sx={{lineHeight: 0}}
          textTransform="capitalize"
          variant="button"
        >
          {t(location.state?.path) || t(title)}
        </MDTypography>
      </MuiBreadcrumbs>
      <MDTypography
        color={light ? "white" : "dark"}
        fontWeight="bold"
        noWrap
        textTransform="capitalize"
        variant="h6"
      >
        {t(location.state?.path) || t(title)}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
