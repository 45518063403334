import CourseHttpService from "../http/backend.http.service";

class ExercisesEndpoint {
	#endpoint = "exercises";

	findAll = async (token) => {
		return await CourseHttpService.get(token, this.#endpoint);
	};

	getExerciseTable = async (token, lessonId) => {
		return await CourseHttpService.get(token, this.#endpoint + "/table-data/lesson/" + lessonId);
	};

	findByLessonId = async (token, lessonId) => {
		return await CourseHttpService.get(token, this.#endpoint + "/lesson/" + lessonId);
	};

	findById = async (token, exerciseId) => {
		return await CourseHttpService.get(token, this.#endpoint + "/" + exerciseId);
	};

}

export default new ExercisesEndpoint();