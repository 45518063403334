const hostname = window?.location?.hostname;
const subdomain = hostname.split(".sge.gov.tr")[0];
let keycloakHost = "";
let baseUrl = "";
if (subdomain.includes("-")) {
  const namespace = subdomain.split("-")[0];
  baseUrl = `https://${namespace}-siberlab.sge.gov.tr`;
} else {
  baseUrl = "https://siberlab.sge.gov.tr";
}
keycloakHost = baseUrl + "/auth";
export const config = {
  url: {
    BACKEND_API_BASE_URL: hostname === "localhost" ? "http://localhost:8080/api/v1" : `https://${hostname}/api/v1`,
    KEYCLOAK_BASE_URL: hostname === "localhost" ? "https://local-siberlab.sge.gov.tr/auth" : keycloakHost,
    GUACAMOLE_BASE_URL: hostname === "localhost" ? "https://local-siberlab.sge.gov.tr/guacamole/#/client/" : `${baseUrl}/guacamole/#/client/`,
  },
  routes: {
    courses: {
      key: "Courses",
      route: "/courses"
    },
    lessons: {
      key: "Lessons",
      route: "/lessons"
    },
    scenarios: {
      key: "Scenarios",
      route: "/scenarios"
    },
    scenario_steps: {
      key: "Scenario-Steps",
      route: "/scenario-steps"
    },
    completed_lessons: {
      key: "Completed-Lessons",
      route: "/completed-lessons"
    },
    ongoing_lessons: {
      key: "Ongoing-Lessons",
      route: "/ongoing-lessons"
    },
    planned_lessons: {
      key: "Planned-Lessons",
      route: "/planned-lessons"
    },
  }
};
