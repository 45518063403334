import * as React from "react";
import {alpha, styled} from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";
import MDButton from "../../MDButton";
import {PlayArrow} from "@mui/icons-material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({theme}) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
            theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
		boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity,
				),
			},
		},
	},
}));

export default function CustomizedMenus({
	row,
	startFunction,
	viewLessonPage,
	viewLessonScenario
}) {
	const {t} = useTranslation([], {useSuspense: false});
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleStart = () => {
		startFunction(row);
		handleClose();
	};

	return (
		<>
			<MDButton
				onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon/>}
				size="small"
				variant="gradient"
				color="info"
			>
				{t("Actions")}
			</MDButton>
			<StyledMenu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{
					startFunction && (
						<>
							<MenuItem onClick={handleStart} disableRipple>
								<PlayArrow/>
								{t("Start")}
							</MenuItem>
							<Divider sx={{my: 0.5}}/>
						</>
					)
				}
				{
					viewLessonPage && (
						<MenuItem onClick={()=> viewLessonPage(row)} disableRipple>
							<PlayCircleFilledIcon/>
							{t("View and Start Lesson")}
						</MenuItem>
					)
				}
				{
					viewLessonScenario && (
						<MenuItem onClick={()=> viewLessonScenario(row)} disableRipple>
							<AutoStoriesIcon/>
							{t("View Lesson Scenario")}
						</MenuItem>
					)
				}
			</StyledMenu>
		</>
	);
}