import React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {MaterialUIControllerProvider} from "context";
import Keycloak from "keycloak-js";
import {config} from "./Constants";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {BrowserRouter} from "react-router-dom";
import {CircularProgress, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import "./i18n";
import RolePermission from "./utils/static/RolePermission";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const keycloak = new Keycloak({
	url: config.url.KEYCLOAK_BASE_URL,
	realm: "siberlab",
	clientId: "siberlab-ui"
});
const initOptions = {pkceMethod: "S256"};

const handleOnEvent = async (event, error) => {
	if (event === "onAuthSuccess") {
		if (keycloak.authenticated) {
			const userRoles = keycloak.tokenParsed.realm_access.roles;
			keycloak.permissions = RolePermission.getPermissions(userRoles);
		}
	}
};

const loadingComponent = (
	<Box
		sx={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			height: "100vh",
		}}
	>
		<Box sx={{textAlign: "center", color: "#4d4d4d"}}>
			<CircularProgress sx={{mb: 2}}/>
			<Typography variant="h6" gutterBottom>
                Keycloak is loading
			</Typography>
			<Typography variant="body2">
                or running authorization code flow with PKCE
			</Typography>
		</Box>
	</Box>

);

root.render(
	<BrowserRouter>
		<ReactKeycloakProvider
			authClient={keycloak}
			initOptions={initOptions}
			LoadingComponent={loadingComponent}
			onEvent={(event, error) => handleOnEvent(event, error)}
		>
			<MaterialUIControllerProvider>
				<App/>
			</MaterialUIControllerProvider>
		</ReactKeycloakProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
