import {Cancel, CheckCircleOutline} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";
import React from "react";

const AppDataTableBooleanCell = ({value}) => {
	return (
		<>
			{
				value ?
					<CheckCircleOutline fontSize="medium" sx={{color: green[500]}}/> :
					<Cancel fontSize="medium" sx={{color: red[500]}}/>
			}
		</>
	);
};

export default AppDataTableBooleanCell;