import React, {useEffect} from "react";
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import MDBox from "../MDBox";
import MDInput from "../MDInput";
import CustomizedMenus from "./components/CustomizedMenus";
import {customStyles} from "./style";

function AppDataTable({
  columns,
  data,
  viewLessonPage,
  viewLessonScenario,
  startFunction,
  hasActions,
  searchable
}) {
  const {t} = useTranslation([], {useSuspense: false});
  if (hasActions) {
    columns.push({
      name: t("Actions"),
      cell: (row) => (
        <CustomizedMenus
          row={row}
          startFunction={startFunction}
          viewLessonPage={viewLessonPage}
          viewLessonScenario={viewLessonScenario}
        />
      ),
      allowOverflow: true,
      width: "200px",
    });
  }

  const [filterText, setFilterText] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(data);
  useEffect(() => {
    if (!filterText) {
      setFilteredData(data);
      return;
    }

    const lowerCaseFilterText = filterText.toLowerCase();

    const filtered = data.filter((item) =>
      Object.values(item)
        .some(
          (value) =>
            value && value.toString()
              .toLowerCase()
              .includes(lowerCaseFilterText),
        ),
    );

    setFilteredData(filtered);
  }, [data, filterText]);

  return (
    <TableContainer sx={{boxShadow: "none"}}>
      {
        searchable && (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder={t("Search...")}
                value={filterText}
                size="small"
                fullWidth
                onChange={(e) => setFilterText(e.target.value)}
              />
            </MDBox>
          </MDBox>
        )
      }
      <DataTable
        columns={columns}
        data={filteredData}
        customStyles={customStyles}
        highlightOnHover
        pointerOnHover
        pagination
        noDataComponent={t("No data available")}
      />
    </TableContainer>
  );
}

export default AppDataTable;
