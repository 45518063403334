import BackendHttpService from "../http/backend.http.service";

class DashboardEndpoint {
  #endpoint = "dashboard";

  userTotalLessonCount = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-total-lesson-count",
    );
  };

  latestLesson = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/latest-lessons",
    );
  };

  userTotalCompletedLessonCount = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-total-completed-lesson-count",
    );
  };

  userPlannedLessonCount = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-planned-lesson-count",
    );
  };

  userOngoingLessonCount = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-ongoing-lesson-count",
    );
  };

  userCompletedLessons = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-completed-lessons",
    );
  };

  userCompletedLessonsTop5 = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-completed-lessons-top-5",
    );
  };

  userOngoingLessonsTop5 = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-ongoing-lessons-top-5",
    );
  };

  userOngoingLessons = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-ongoing-lessons",
    );
  };

  userPlannedLessonsTop5 = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-planned-lessons-top-5",
    );
  };

  userPlannedLessons = async (token) => {
    return await BackendHttpService.get(
      token,
      this.#endpoint + "/user-planned-lessons",
    );
  };
}

export default new DashboardEndpoint();
